:root {
  --color-primary-900: #00000b;
  --color-primary-800: #000619;
  --color-primary-700: #001733;
  --color-primary-600: #0c3857;
  --color-primary-500: #1e4c6a;
  --color-primary-400: #235272;
  --color-primary-300: #31617d;
  --color-primary-200: #4476a5;
  --color-primary-100: #7cacd9;
  --color-primary-50: #9ec7ee;
  --color-secondary-800: #ff2a57;
  --color-secondary-700: #f16179;
  --color-secondary-600: #f7909f;
  --color-secondary-300: #ffc8d6;
  --color-secondary-100: #ffe9f0;
  --color-success-100: #01bbac;
  --color-success-60: #01bbac99;
  --color-success-30: #01bbac4d;
  --color-success-20: #01bbac33;
  --color-success-15: #01bbac26;
  --color-success-10: #01bbac1a;
  --color-alert-100: #ff9549;
  --color-alert-60: #ff954999;
  --color-alert-30: #ff95494d;
  --color-alert-20: #ff954933;
  --color-alert-10: #ff95491a;
  --color-information-100: #0078e8;
  --color-information-60: #0078e899;
  --color-information-30: #0078e84d;
  --color-information-15: #0078e826;
  --color-error-100: #f52020;
  --color-error-60: #f5202099;
  --color-error-30: #f520204d;
  --color-error-20: #f5202033;
  --color-error-10: #f520201a;
  --color-white: #fff;
  --color-white-90: #ffffffe6;
  --color-white-80: #fffc;
  --color-white-70: #ffffffb2;
  --color-white-60: #fff9;
  --color-white-50: #ffffff7f;
  --color-white-40: #fff6;
  --color-white-30: #ffffff4d;
  --color-white-20: #ffffff4c;
  --color-white-10: #ffffff19;
  --color-white-05: #ffffff0c;
  --color-white-03: #ffffff07;
  --color-black: #000;
  --color-black-50: #00000080;
  --color-black-25: #01001440;
  --color-black-20: #01001433;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: IBM Plex Sans, sans-serif;
}

html {
  background-color: var(--color-primary-950);
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
}

body {
  color: var(--color-white);
  min-width: 360px;
  max-width: 100%;
  height: 100%;
  margin: 0;
}

#app {
  height: 100%;
}

@font-face {
  font-family: Chronica;
  src: url("Chronica.51c5a699.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.loader {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.dot-pulse {
  background-color: var(--color-secondary-800);
  color: var(--color-secondary-800);
  border-radius: 5px;
  width: 10px;
  height: 10px;
  animation: 1.5s linear .25s infinite dot-pulse;
  position: relative;
  left: -9999px;
  box-shadow: 9999px 0 0 -5px;
}

.dot-pulse:before, .dot-pulse:after {
  content: "";
  background-color: var(--color-secondary-800);
  color: var(--color-secondary-800);
  border-radius: 5px;
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-pulse:before {
  animation: 1.5s linear infinite dot-pulse-before;
  box-shadow: 9984px 0 0 -5px;
}

.dot-pulse:after {
  animation: 1.5s linear .5s infinite dot-pulse-after;
  box-shadow: 10014px 0 0 -5px;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.header {
  background: linear-gradient(to right, var(--color-primary-400), var(--color-primary-800));
  border-bottom: 2px solid var(--color-primary-800);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
}

.header-container {
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header-container img {
  margin-right: 10px;
}

.header-container-name {
  letter-spacing: 5px;
  text-shadow: -1px -1px #000, 1px -1px #000, -1px 1px #000, 1px 1px #000;
  font-family: Chronica, sans-serif;
  font-size: 48px;
  transform: translateY(6px);
}

@media only screen and (width >= 600px) {
  .header-container-name {
    letter-spacing: 10px;
    font-size: 52px;
  }
}

.header-back-button {
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: none;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.header-back-button:hover {
  opacity: .8;
}

@media only screen and (width >= 1024px) {
  .header-back-button {
    display: flex;
  }
}

.header-cart {
  cursor: pointer;
  color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: none;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.header-cart:hover {
  opacity: .8;
}

@media only screen and (width >= 1024px) {
  .header-cart {
    display: flex;
  }
}

.header-mobile {
  background-color: var(--color-primary-700);
  align-items: center;
  gap: 8px;
  height: 46px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .header-mobile {
    display: none;
  }
}

.header-mobile-back-button {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.header-mobile-back-button:hover {
  opacity: .8;
}

.header-mobile-cart {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  display: flex;
}

.header-mobile-cart:hover {
  opacity: .8;
}

.footer {
  background: linear-gradient(to right, var(--color-primary-400), var(--color-primary-800));
  border-bottom: 2px solid var(--color-primary-800);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  margin-top: 12px;
  display: flex;
}

.footer-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

@media only screen and (width >= 600px) {
  .footer-container {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.footer-container-socials {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: flex;
}

.footer-container-socials a {
  justify-content: center;
  align-items: center;
  display: flex;
}

.item-image {
  aspect-ratio: 3 / 4;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  gap: 10px;
  display: flex;
  position: relative;
}

.item-image-loader {
  z-index: 1;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.item-image img {
  object-fit: cover;
  z-index: 2;
  border-radius: 12px;
  width: 100%;
  max-width: 700px;
  height: 100%;
}

@media only screen and (width >= 1280px) {
  .item-image img {
    max-width: 100%;
  }
}

.item-image-arrow {
  cursor: pointer;
  z-index: 3;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  display: flex;
  position: absolute;
}

.item-image-arrow-icon {
  color: #fff;
  background-color: var(--color-primary-200);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.item-image-arrow.left {
  top: 46%;
  left: 0;
}

.item-image-arrow.right {
  top: 46%;
  right: 0;
}
/*# sourceMappingURL=index.24d80e2a.css.map */
