@use "src/globals.scss" as *;

.item-image {
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;

  aspect-ratio: 3/4;
  user-select: none;

  &-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    z-index: 2;

    max-width: 700px;
    @include midDesktop {
      max-width: 100%;
    }
  }

  &-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 45px;
    height: 45px;

    position: absolute;
    z-index: 3;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      color: white;
      border-radius: 12px;
      background-color: var(--color-primary-200);

      width: 30px;
      height: 30px;
    }

    &.left {
      left: 0px;
      top: 46%;
    }

    &.right {
      right: 0px;
      top: 46%;
    }
  }
}
