@use "src/globals.scss" as *;

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 12px;
  width: 100%;
  height: 60px;
  background: linear-gradient(
    to right,
    var(--color-primary-400),
    var(--color-primary-800)
  );
  border-bottom: 2px solid var(--color-primary-800);

  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    @include tablet {
      padding-left: 48px;
      padding-right: 48px;
    }

    &-socials {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
