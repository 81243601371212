$tabletBreakpoint: 600px;
$desktopBreakpoint: 1024px;
$midDesktopBreakpoint: 1280px;
$bigDesktopBreakpoint: 1440px;

@mixin tablet {
  @media only screen and (min-width: $tabletBreakpoint) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $desktopBreakpoint) {
    @content;
  }
}

@mixin midDesktop {
  @media only screen and (min-width: $midDesktopBreakpoint) {
    @content;
  }
}

@mixin bigDesktop {
  @media only screen and (min-width: $bigDesktopBreakpoint) {
    @content;
  }
}
