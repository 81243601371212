:root {
  --color-primary-900: #00000b;
  --color-primary-800: #000619;
  --color-primary-700: #001733;
  --color-primary-600: #0c3857;
  --color-primary-500: #1e4c6a;
  --color-primary-400: #235272;
  --color-primary-300: #31617d;
  --color-primary-200: #4476a5;
  --color-primary-100: #7cacd9;
  --color-primary-50: #9ec7ee;

  --color-secondary-800: #ff2a57;
  --color-secondary-700: #f16179;
  --color-secondary-600: #f7909f;
  --color-secondary-300: #ffc8d6;
  --color-secondary-100: #ffe9f0;

  --color-success-100: #01bbac;
  --color-success-60: #01bbac99;
  --color-success-30: #01bbac4d;
  --color-success-20: #01bbac33;
  --color-success-15: #01bbac26;
  --color-success-10: #01bbac1a;

  --color-alert-100: #ff9549;
  --color-alert-60: #ff954999;
  --color-alert-30: #ff95494d;
  --color-alert-20: #ff954933;
  --color-alert-10: #ff95491a;

  --color-information-100: #0078e8;
  --color-information-60: #0078e899;
  --color-information-30: #0078e84d;
  --color-information-15: #0078e826;

  --color-error-100: #f52020;
  --color-error-60: #f5202099;
  --color-error-30: #f520204d;
  --color-error-20: #f5202033;
  --color-error-10: #f520201a;

  --color-white: #ffffff;
  --color-white-90: #ffffffe6;
  --color-white-80: #ffffffcc;
  --color-white-70: #ffffffb2;
  --color-white-60: #ffffff99;
  --color-white-50: #ffffff7f;
  --color-white-40: #ffffff66;
  --color-white-30: #ffffff4d;
  --color-white-20: #ffffff4c;
  --color-white-10: #ffffff19;
  --color-white-05: #ffffff0c;
  --color-white-03: #ffffff07;

  --color-black: #000000;
  --color-black-50: #00000080;
  --color-black-25: #01001440;
  --color-black-20: #01001433;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans", sans-serif;
}

html {
  background-color: var(--color-primary-950);
  // scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
}

body {
  // background-color: var(--color-primary-950);
  color: var(--color-white);
  margin: 0;
  min-width: 360px;
  max-width: 100%;
  height: 100%;
}

#app {
  height: 100%;
}

@font-face {
  font-family: "Chronica";
  src: url("./Chronica.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
