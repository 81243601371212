@use "src/globals.scss" as *;

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 100%;
  height: 70px;
  background: linear-gradient(
    to right,
    var(--color-primary-400),
    var(--color-primary-800)
  );
  border-bottom: 2px solid var(--color-primary-800);

  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      margin-right: 10px;
    }

    &-name {
      font-family: "Chronica", sans-serif;
      font-size: 48px;
      letter-spacing: 5px;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;

      transform: translateY(6px);

      @include tablet {
        font-size: 52px;
        letter-spacing: 10px;
      }
    }
  }

  &-back-button {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);

    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;

    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    display: none;
    @include desktop {
      display: flex;
    }
  }

  &-cart {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    color: white;

    display: none;
    @include desktop {
      display: flex;
    }
  }

  &-mobile {
    align-items: center;
    gap: 8px;
    height: 46px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: var(--color-primary-700);

    display: flex;

    @include desktop {
      display: none;
    }

    &-back-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    &-cart {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-left: auto;

      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
